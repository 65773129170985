// Importation du module Firebase complet
import firebase from "firebase/app";
import "firebase/messaging"; // Importer la messagerie Firebase

// Votre configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBlqh1uu3YWULwEycRyibZe1IyBtUY4eP8",
  authDomain: "rapiddishapp-ec732.firebaseapp.com",
  projectId: "rapiddishapp-ec732",
  storageBucket: "rapiddishapp-ec732.appspot.com",
  messagingSenderId: "55370132074",
  appId: "1:55370132074:web:4295949379b3d28bf5f813"
};

// Initialiser Firebase
firebase.initializeApp(firebaseConfig);

// Initialiser le service de messagerie
const messaging = firebase.messaging();

// Fonction pour demander la permission de recevoir des notifications
export const requestFirebaseNotificationPermission = async (clientId) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        console.log('Permission de notification accordée.');
        const token = await messaging.getToken({ vapidKey: 'BK5hOsQUc7lxw5Iqz_tP2o2ystH5uRN5R8ZG0aMlbNtLIsdHaDCjKGDk_8fXQWF9y6waGVZrnU3vlfODR7K_Wr4' });
        sendTokenToServer(token,clientId);  // Envoyer le token au serveur
        return token;
      } else {
        console.error('Permission de notification refusée.');
        throw new Error('Permission refusée');
      }
    } catch (error) {
      console.error('Erreur lors de la demande de permission de notification:', error);
      throw error;
    }
  };
// Fonction pour récupérer le token Firebase
export const getToken = async (setTokenFound) => {
  try {
    const currentToken = await messaging.getToken({ vapidKey: 'BK5hOsQUc7lxw5Iqz_tP2o2ystH5uRN5R8ZG0aMlbNtLIsdHaDCjKGDk_8fXQWF9y6waGVZrnU3vlfODR7K_Wr4' });
    if (currentToken) {
      console.log('Token généré:', currentToken);
      setTokenFound(true);
      return currentToken;
    } else {
      console.log('Aucun token de registration disponible.');
      setTokenFound(false);
    }
  } catch (error) {
    console.log('Une erreur s\'est produite lors de la récupération du token.', error);
    setTokenFound(false);
  }
};
// Fonction pour envoyer le token Firebase au serveur
const sendTokenToServer = async (token, clientId) => {
    try {
      const response = await fetch(`${window.env.REACT_APP_BASE_URL}save_token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          client_id: clientId  // Ajoutez l'identifiant du client ici
        })
      });
      const data = await response.json();
      console.log('Token sent to server:', data);
    } catch (error) {
      console.error('Failed to send token to server:', error);
    }
};

// Écouter les messages entrants lorsque l'application est en premier plan
export const onMessageListener = () => 
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

